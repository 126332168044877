<script setup lang="ts">
import type { Promotion } from "~/types";

type PromotionType = Promotion["type"];

const { t } = useT();

const props = defineProps<{
	image: string;
	subTitle: string;
	title: string;
	coins: number;
	entries: number;
	type?: PromotionType;
	currencySymbol?: string;
	money?: string;
	promotionInfo?: boolean;
	badge?: Record<string | number, string | number | boolean | null | undefined>;
	imageBadge?: string;
	usualPrice?: string;
	profileCompleted?: boolean;
	bestDeal?: boolean;
	mostPopular?: boolean;
	subType?: boolean;
}>();

const emit = defineEmits<{ (event: "click"): void }>();

const secretOfferImg = computed(() => (props.profileCompleted ? props.image : "/nuxt-img/promotions/secret-card.webp"));
const secretOfferSubtitle = computed(() => (props.profileCompleted ? props.subTitle : t("Secret Offer")));
</script>

<template>
	<ACard
		:imgAttrs="{
			src: secretOfferImg,
			width: 316,
			height: 392,
			format: 'avif',
			loading: 'lazy',
			alt: 'secret-promotion-img'
		}"
		width="316px"
		height="392px"
		@click="emit('click')"
	>
		<template #top>
			<div v-if="profileCompleted" class="badge-wrapper">
				<ABadge
					v-if="bestDeal || mostPopular"
					:background="bestDeal ? 'var(--goeteborg)' : 'var(--greenburgh)'"
					variant="info"
					autosize
				>
					<AText variant="topeka" class="badge text-cannes" :modifiers="['semibold', 'uppercase']">
						{{ bestDeal ? t("Best deal") : t("Most popular") }}
					</AText>
				</ABadge>

				<ABadge v-if="subType" class="super" background="var(--guanare)" variant="info" autosize>
					<AText variant="topeka" class="badge text-cannes" :modifiers="['semibold', 'uppercase']">
						{{ t("Super offer ") }}
					</AText>
				</ABadge>
			</div>
		</template>
		<template #bottom>
			<div class="box">
				<div v-if="badge && badge.percent && badge.text && profileCompleted" class="label">
					<NuxtImg
						v-if="imageBadge"
						:src="imageBadge"
						class="icon"
						width="56"
						height="56"
						alt="Rabbit coins prize"
						format="avif"
					/>

					<AText variant="tempe" :modifiers="['medium']"> {{ badge?.percent }} </AText>
					<AText variant="topeka" :modifiers="['normal']"> {{ badge?.text }} </AText>
				</div>

				<AText v-if="subTitle" class="sub-title" variant="toledo" :modifiers="['semibold']">
					{{ secretOfferSubtitle }}
				</AText>

				<AText v-if="!profileCompleted" class="text-cadiz">
					{{ t("Pull it out of the hat!") }}
				</AText>

				<AText v-if="title" class="text-cadiz">
					{{ title }}
				</AText>

				<div v-if="profileCompleted">
					<MPrizeFund v-if="coins" variant="coins" icon="12/coins" :icon-size="26">
						<AText variant="tanzay" class="text-count" :modifiers="['semibold', 'uppercase']">
							{{ numberFormat(coins) }}
						</AText>
					</MPrizeFund>

					<div class="multi-prize-wrapper">
						<AText v-if="coins && entries" variant="taipei" :modifiers="['semibold', 'uppercase']">+</AText>
						<MPrizeFund
							v-if="entries"
							variant="entries"
							:class="{ 'multi-prize': coins && entries }"
							icon="12/secret-coins"
							:icon-size="22"
						>
							<AText variant="taipei" :modifiers="['semibold', 'uppercase']" class="text-count">
								{{ numberFormat(entries) }}
							</AText>
							<AText
								v-if="!promotionInfo"
								variant="ternopil"
								class="count-after"
								:modifiers="['semibold', 'uppercase']"
							>
								{{ t("Free Super Coins") }}
							</AText>
						</MPrizeFund>
					</div>
				</div>

				<AButton variant="primary" size="lg" class="promo-btn">
					<template v-if="profileCompleted">
						<AText variant="toledo" :modifiers="['medium']">
							{{ t("Buy Now") }}
						</AText>
						<AText v-if="usualPrice" variant="toledo" :modifiers="['medium']" class="text-coyoacan">
							{{ currencySymbol }}<s>{{ usualPrice }}</s>
						</AText>
						<AText variant="toledo" :modifiers="['medium']">{{ currencySymbol }}{{ money }}</AText>
					</template>
					<template v-else>
						<AText :modifiers="['medium']" variant="toledo">
							{{ t("Pull Out") }}
						</AText>
					</template>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	color: var(--coro);
	background: var(--graz);
	border: 2px solid var(--celaya);
	border-radius: 16px;
	overflow: visible;

	&:deep(> img) {
		margin: auto;
		border-radius: 14px;
	}

	.badge-wrapper {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		gap: 8px;
	}

	.multi-prize-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;
	}

	.badge {
		height: 26px;
		display: flex;
		align-items: center;
		padding-right: 4px;
		padding-left: 4px;
		box-shadow: none;
		&.free {
			border: 2px solid var(--cuautitlan);
			& > span {
				color: var(--cuautitlan);
			}
		}
	}

	.count-after {
		text-align: left;
		max-width: 70px;
	}

	.box {
		position: absolute;
		left: calc(50% - 142px);
		bottom: -28px;
		border-radius: 16px;
		border: 2px solid var(--cordoba);
		background: var(--clovis);
		backdrop-filter: blur(45px);
		padding: 12px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
		width: 284px;
		height: auto;
		gap: 8px;
	}

	.label {
		width: 56px;
		height: 56px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: -15px;
		right: -15px;

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
	}

	.promo-btn {
		display: flex;
		gap: 3px;
	}
}
</style>
